import { Injectable } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { User } from 'src/app/interfaces/user.data';
import * as Constants from 'src/app/shared/constants'
import { FormService } from 'src/app/services/form/form.service';
import { AuthService } from 'src/app/services/auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  public userObj = new User();
  public user: BehaviorSubject<User> = new BehaviorSubject<User>(this.userObj);
  public newMessages: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private awaitResp = false;
  private authkey = '';
  constructor(
    private authServ: AuthService,
    private formServ: FormService
  ) {
    this.init();
  }
  async init() {
    // console.log('init user service');
    await this.getAuthKey();
    if (this.authkey) {
      this.getProfile();
    }
  }
  async getAuthKey() {
    if (!this.authkey) {
      this.authkey = await this.authServ.getAuthkey();
    }
    return this.authkey;
  }
  async userLogin(logindata) {
    const url = '/loginapi.php';
    const apiResp = await this.formServ.httpPost(logindata, url);
    if (apiResp.status == 'success') {
      this.userObj = apiResp.user;
      this.authServ.setAuthkey(this.userObj.id);
      this.userObj.loggedIn = true;
      this.user.next(this.userObj);
      return this.userObj;
    } else {
      return false;
    }
  }
  async getProfile() {
    const url = '/profile_api.php';
    const apiResp = await this.formServ.httpPost({ id: await this.authServ.getAuthkey() }, url, false);
    if (apiResp.status == 'success') {
      this.userObj = apiResp.profile_details_api[0];
      this.authServ.setAuthkey(this.userObj.id);
      this.userObj.loggedIn = true;
      this.user.next(this.userObj);
      return this.userObj;
    } else {
      return false;
    }
  }
  async getStates() {
    const url = '/states.php';
    const resp = await this.formServ.httpPost({}, url, false);
    if (resp.states) {
      return resp.states;
    } else {
      return [];
    }
  }
  async registerUser(userdata) {
    const url = '/registerapi.php';
    const apiResp = await this.formServ.httpPost(userdata, url);
    return apiResp;
  }
  async contactUs(contactdata) {
    const url = '/contactUs.php';
    const apiResp = await this.formServ.httpPost(contactdata, url);
    return apiResp;
  }
  async UpdateProfile(userdata) {
    const url = '/editprofile.php';
    const apiResp = await this.formServ.httpPost(userdata, url);
    return apiResp;
  }
  async deleteProfile() {
    const url = '/otp_delete_account.php';
    const apiResp = await this.formServ.httpPost({ id: await this.authServ.getAuthkey() }, url);
    return apiResp;
  }
  async deleteProfileOtp(userdata) {
    userdata['id'] = await this.authServ.getAuthkey();
    const url = 'otp_check_account.php';
    const apiResp = await this.formServ.httpPost(userdata, url);
    return apiResp;
  }
  async getpackages() {
    const url = '/packages.php';
    const resp = await this.formServ.httpPost({}, url);
    if (resp.packages) {
      return resp.packages;
    } else {
      return [];
    }
  }
  async checkpackage(packageId) {
    const url = '/checkSubscribed.php';
    const resp = await this.formServ.httpPost({ id: packageId, user_id: await this.authServ.getAuthkey() }, url);
    if (resp.subscribed_package) {
      return resp.subscribed_package;
    } else {
      return [];
    }
  }
  async UpdatePwd(pwdsetting) {
    const url = '/resetPassword.php';
    const apiResp = await this.formServ.httpPost(pwdsetting, url);
    return apiResp;
  }
  async logout() {
    this.authkey = null;
    this.userObj = new User();
    this.user.next(this.userObj);
    this.authServ.clear();
  }
  async getItems(cType) {
    const url = '/content_type_details.php';
    const resp = await this.formServ.httpPost({ user_id: await this.authServ.getAuthkey(), id: cType }, url);
    if (resp['status'] == 'access_denied') {
      return ['access_denied'];
    } else if (resp['content-type_details']) {
      return resp['content-type_details'];
    } else {
      return [];
    }
  }
  async getSlides() {
    const url = '/slider.php';
    const resp = await this.formServ.httpPost({ user_id: await this.authServ.getAuthkey() }, url);
    if (resp['slides']) {
      return resp['slides'];
    } else {
      return [];
    }
  }
  async getServices() {
    const url = '/services.php';
    const resp = await this.formServ.httpPost({ user_id: await this.authServ.getAuthkey() }, url, false);
    if (resp['services']) {
      return resp['services'];
    } else {
      return [];
    }
  }
  async getResources() {
    const url = '/otherResources.php';
    const resp = await this.formServ.httpPost({ user_id: await this.authServ.getAuthkey() }, url, false);
    if (resp['otherResources']) {
      return resp['otherResources'];
    } else {
      return [];
    }
  }

  async getBlogs() {
    const url = '/blogs.php';
    const resp = await this.formServ.httpPost({ user_id: await this.authServ.getAuthkey() }, url);
    if (resp['blogs']) {
      return resp['blogs'];
    } else {
      return [];
    }
  }
  async getTransactions() {
    const url = '/mytransaction.php';
    const resp = await this.formServ.httpPost({ id: await this.authServ.getAuthkey() }, url);
    if (resp['mytransaction']) {
      return resp['mytransaction'];
    } else {
      return [];
    }
  }
  async addTransaction(packageData) {
    const url = '/insertTransaction.php';
    packageData[0]['user_id'] = await this.authServ.getAuthkey()
    const resp = await this.formServ.httpPost(packageData, url, true, 'json');
    if (resp && resp['transaction_id']) {
      return resp;
    } else {
      return [];
    }
  }
}


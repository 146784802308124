import { Component, OnInit } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { User } from 'src/app/interfaces/user.data';
import { UserService } from 'src/app/services/user/user.service';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  user = new User();
  msgCount = 0;
  subscription: Subscription;
  subscriptionMsg: Subscription;

  constructor(
    private userServ: UserService,
    private navCtrl: NavController,
    private menuCtrl: MenuController
  ) { }

  async ngOnInit() {
    this.subscription = await this.userServ.user.subscribe(async u => {
      this.user = u;
    });
  }
  async logout() {
    await this.userServ.logout();
    document.getElementsByClassName('menu-content-open').item(0).classList.remove('menu-content-open');
    setTimeout(() => {
      this.navCtrl.navigateRoot('/');
    }, 1000);
  }
  async openSource() {
    const link = 'https://cmapis.cmots.com/CNIGlobal/BseNseChart.aspx';
    await Browser.open({ url: link });
  }
}

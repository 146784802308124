export const INT_URL = 'https://cniinfoxchange.com/api';
export const EXT_URL = 'https://cniinfoxchange.com/api';
export const API_PATH = '';
export const UPLOAD_PATH = '/';
export const PUB_API_PATH = '';
export const USER_API_PATH = '';
export const VERSION = '1.0.1';

export const REGEX_EMAIL = '^[a-zA-Z0-9.]+@[a-zA-Z0-9-]+[\.]+[a-zA-Z0-9]+(?:\.[a-zA-Z0-9-]+)*$';
export const REGEX_PHONE = /(^[6-9]{1})+([0-9]{9})$/;
export const REGEX_PASSWORD = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{4,}/;

import { Component } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { UserService } from './services/user/user.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  userSubscription: any;
  user: any = {};
  constructor(
    private userServ: UserService
  ) {
    SplashScreen.show();
    this.userSubscription = this.userServ.user.subscribe(user => {
      this.user = user;
    })
  }

}

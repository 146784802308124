import { Component, OnInit } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { UserService } from 'src/app/services/user/user.service';
import { SplashScreen } from '@capacitor/splash-screen';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  logindata: any = {};
  userSubscription: any;
  user: any = { loggidIn: false };
  checkedLogin = false;
  constructor(
    private userServ: UserService,
    private alertCtrl: AlertController,
    private navCtrl: NavController,
    private notifyServ: NotificationService
  ) { }

  async ngOnInit() {
    // if (await this.userServ.getAuthKey) {
    //   this.navCtrl.navigateForward('/home');
    // }
    this.notifyServ.getToken();
  }
  ionViewDidEnter() {
    this.checkedLogin = false;
    this.userSubscription = this.userServ.user.subscribe(user => {
      this.user = user;
      if (user.loggedIn) {
        this.navCtrl.navigateForward('/home');
      } else {
        SplashScreen.hide();
      }
      this.checkedLogin = true;
    })
  }
  async submitlogin() {
    this.logindata.gcmtoken = await this.notifyServ.getToken();
    const resp: any = await this.userServ.userLogin(this.logindata);
    if (resp) {
      const alert = await this.alertCtrl.create({
        message: 'You are sucessfully login',
      });
      await alert.present();
      setTimeout(() => {
        alert.dismiss();
      }, 1000);
      this.navCtrl.navigateForward('/home');
    } else {
      const alert = await this.alertCtrl.create({
        message: 'Invalid login',
      });
      await alert.present();
    }
  }
  async submitform() {
    const valid = await this.loginvalidation();
    if (valid == 'valid') {
      this.submitlogin();
    } else {
      const alert = await this.alertCtrl.create({
        header: 'Validation Error',
        message: valid,
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {

            }
          }
        ]
      });
      await alert.present();
    }
  }
  async loginvalidation() {
    if (!this.logindata.email || /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.logindata.email) == false) {
      return 'Enter a valid Email.';
    }
    if (!this.logindata.password) {
      return 'Enter your Password.';
    }
    return 'valid';
  }
  ionViewWillLeave() {
    this.userSubscription.unsubscribe();
  }

}

export class User {
    loggedIn = false;
    mobile = '';
    email = '';
    fullname = '';
    id = '';
    username = '';
    category = '';
    state = '';
    password = '';
}

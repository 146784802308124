import { Injectable } from '@angular/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  token: any = '';
  constructor(
    private alertCtrl: AlertController
  ) {
    this.init()
  }

  init() {
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    PushNotifications.addListener('registration', (token: Token) => {
      // console.log('Push registration success, token: ' + token.value);
      this.token = token.value;
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        // console.log('Push received: ' + JSON.stringify(notification));
        const alert = await this.alertCtrl.create({
          header: notification.title,
          message: notification.body,
          buttons: [
            {
              text: 'Ok',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {

              }
            }
          ]
        });
        await alert.present();
      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
      },
    );
  }

  async getToken() {
    let retry = 5;
    while (!this.token && retry > 0) {
      retry--;
      try {
        await PushNotifications.requestPermissions().then(async result => {
          if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
          } else {
            // Show some error
          }
          // console.log(PushNotifications.listChannels());
        });
      } catch (e) {
        console.log(e)
      }
    }
    return this.token;
  }


}
